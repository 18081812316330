import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "main"
  }, [_c("a-card", {
    staticClass: "orderInfo"
  }, [_c("div", {
    staticClass: "title"
  }, [_c("h3", [_c("span", {
    staticClass: "fs_16"
  }, [_vm._v("订单号:")]), _c("span", {
    staticClass: "ml-15 fs_16"
  }, [_vm._v(_vm._s(_vm.info.orderno || "--"))]), _c("span", [_c("img", {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.info.orderno,
      expression: "info.orderno",
      arg: "copy"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.orderCopy,
      expression: "orderCopy",
      arg: "success"
    }],
    staticClass: "copy_icon",
    attrs: {
      src: _vm.copyIcon,
      alt: ""
    }
  })])])]), _c("div", {
    staticClass: "content"
  }, [_c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("商家")]), _c("span", {
    staticClass: "contVal"
  }, [_vm._v(_vm._s(_vm.sellerType(_vm.info.sellertype) + " " + (_vm.info.sellereamil || "--")))])]), _c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("订单类型")]), _c("span", {
    staticClass: "contVal"
  }, [_vm._v(_vm._s(_vm.saleOrderType(_vm.info.type)))])]), _c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("订单总额")]), _c("span", {
    staticClass: "contVal",
    staticStyle: {
      color: "#FF6262",
      "font-size": "18px",
      "font-weight": "600"
    }
  }, [_vm._v("USD " + _vm._s(_vm.info.amount || "--"))])]), _c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("买家")]), _c("span", {
    staticClass: "contVal"
  }, [_vm._v(_vm._s(_vm.info.buyereamil || "--"))])]), _c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("订单来源")]), _c("span", {
    staticClass: "contVal"
  }, [_vm._v(_vm._s(_vm.orderForm || "--"))])]), _c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("订单状态")]), _vm.info.closingreason ? _c("span", {
    staticClass: "contVal"
  }, [_vm.info.closingreason ? _c("span", [_vm._v(_vm._s(_vm.info.closingreason))]) : _vm._e()]) : _c("span", {
    staticClass: "contVal"
  }, [_c("span", [_vm._v(_vm._s(_vm.saleOrderStatus(_vm.info.status)))])])])])]), _c("a-card", {
    staticClass: "proInfo"
  }, [_c("h3", {
    staticClass: "title"
  }, [_vm._v("商品信息")]), _vm.info.list && _vm.info.list.length ? _c("div", {
    staticClass: "content prod_flex"
  }, [_c("div", {
    staticClass: "proImg img_left"
  }, [_c("img", {
    attrs: {
      src: _vm.info.list[0].imgurls.split("|")[0],
      alt: ""
    },
    on: {
      click: function click($event) {
        _vm.preview(_vm.info.list[0].imgurls.split("|")[0]);
      }
    }
  })]), _c("div", {
    staticClass: "pro_detail"
  }, [_c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("货号")]), _c("span", {
    staticClass: "contVal"
  }, [_vm._v(_vm._s(_vm.info.list[0].sku || "--"))])]), _c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("颜色")]), _c("span", {
    staticClass: "contVal"
  }, [_vm._v(_vm._s(_vm.info.list[0].color || "--"))])]), _c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("数量")]), _c("span", {
    staticClass: "contVal"
  }, [_vm._v(_vm._s(_vm.info.list[0].num || "--"))])]), _c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("商品名称")]), _c("span", {
    staticClass: "contVal"
  }, [_vm._v(_vm._s(_vm.info.list[0].productname || "--"))])]), _c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("尺码")]), _c("span", {
    staticClass: "contVal"
  }, [_vm._v(_vm._s(_vm.info.list[0].size || "--"))])]), _c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("单价($)")]), _c("span", {
    staticClass: "contVal"
  }, [_vm._v(_vm._s(_vm.info.list[0].productprice || "--"))])])])]) : _vm._e()]), _c("a-card", {
    staticClass: "logInfo"
  }, [_c("h3", [_vm._v("收发货信息")]), _c("div", {
    staticClass: "content"
  }, [_c("div", [_c("p", {
    staticClass: "contitle"
  }, [_vm._v("发货人信息")]), _c("div", {
    staticClass: "logDetail"
  }, [_c("div", {
    staticClass: "address_info"
  }, [_c("span", {
    staticClass: "bold_user"
  }, [_vm._v(" " + _vm._s(_vm.sendInfo.name || "") + " ")]), _c("span", {
    staticClass: "bold_user bold_user_second"
  }, [_vm._v(_vm._s(_vm.sendInfo.mobile))]), _c("div", {
    staticStyle: {
      height: "10px"
    }
  }), _c("div", {
    staticClass: "addr"
  }, [_c("div", {
    staticClass: "address add_1"
  }, [_c("span", {
    staticClass: "code_margin_right"
  }, [_vm._v(_vm._s(_vm.sendInfo.postalcode))])]), _c("div", {
    staticClass: "address address_second add_2"
  }, [_vm._v(_vm._s(_vm.sendInfo.address))])])])])]), _c("div", [_c("p", {
    staticClass: "contitle"
  }, [_vm._v("收货仓信息")]), _c("div", {
    staticClass: "logDetail"
  }, [_c("div", {
    staticClass: "address_info"
  }, [_c("span", {
    staticClass: "bold_user"
  }, [_vm._v(" " + _vm._s(_vm.wareInfo.name || "") + " ")]), _c("span", {
    staticClass: "bold_user bold_user_second"
  }, [_vm._v(_vm._s(_vm.wareInfo.mobile))]), _c("div", {
    staticStyle: {
      height: "10px"
    }
  }), _c("div", {
    staticClass: "addr"
  }, [_c("div", {
    staticClass: "address add_1"
  }, [_vm._v(_vm._s(_vm.wareInfo.postalcode))]), _c("div", {
    staticClass: "address address_second add_2"
  }, [_vm._v(" " + _vm._s(_vm.wareInfo.address) + " ")])])])])]), _c("div", [_c("p", {
    staticClass: "contitle"
  }, [_vm._v("收货人信息")]), _c("div", {
    staticClass: "logDetail"
  }, [_c("div", {
    staticClass: "address_info"
  }, [_c("span", {
    staticClass: "bold_user"
  }, [_vm._v(" " + _vm._s(_vm.receiveInfo.name || "") + " ")]), _c("span", {
    staticClass: "bold_user bold_user_second"
  }, [_vm._v(_vm._s(_vm.receiveInfo.mobile))]), _c("div", {
    staticStyle: {
      height: "10px"
    }
  }), _c("div", {
    staticClass: "addr"
  }, [_c("div", {
    staticClass: "address add_1"
  }, [_c("span", {
    staticClass: "code_margin_right"
  }, [_vm._v(_vm._s(_vm.receiveInfo.postalcode))])]), _c("div", {
    staticClass: "address address_second add_2"
  }, [_vm._v(" " + _vm._s(_vm.receiveInfo.address) + " ")])])])])])])]), _c("a-card", {
    staticClass: "payInfo"
  }, [_c("h3", [_vm._v("支付信息")]), _c("div", {
    staticClass: "detail"
  }, [_c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("支付时间")]), _c("span", {
    staticClass: "contVal"
  }, [_vm._v(_vm._s(_vm.info.createtime))])]), _c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("支付方式")]), _c("span", {
    staticClass: "contVal"
  }, [_vm._v(_vm._s(_vm.paytype(_vm.info.paytype)))])]), _c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("第三方交易流水号")]), _c("span", {
    staticClass: "contVal"
  }, [_vm._v(_vm._s(_vm.info.tradeno || "--"))])])]), _c("div", {
    staticStyle: {
      height: "16px"
    }
  }), _c("div", {
    staticClass: "content"
  }, [_c("div", [_c("p", {
    staticClass: "contitle"
  }, [_vm._v("费用")]), _c("div", {
    staticClass: "logDetail payinfo_bg"
  }, [_vm._v("金额")])]), _c("div", [_c("p", {
    staticClass: "contitle"
  }, [_vm._v("商品金额")]), _c("div", {
    staticClass: "logDetail payinfo_bg"
  }, [_vm._v(_vm._s(_vm.info.salesprice || "--"))])]), _c("div", [_c("p", {
    staticClass: "contitle"
  }, [_vm._v("运费")]), _c("div", {
    staticClass: "logDetail payinfo_bg"
  }, [_vm._v(_vm._s(_vm.info.userexpressprice || "--"))])]), _c("div", [_c("p", {
    staticClass: "contitle"
  }, [_vm._v("手续费")]), _c("div", {
    staticClass: "logDetail payinfo_bg"
  }, [_vm._v(_vm._s(_vm.info.userserviceprice || "--"))])]), _c("div", [_c("p", {
    staticClass: "contitle"
  }, [_vm._v("消费税")]), _c("div", {
    staticClass: "logDetail payinfo_bg"
  }, [_vm._v(_vm._s(_vm.info.usertax || "--"))])]), _c("div", [_c("p", {
    staticClass: "contitle"
  }, [_vm._v("折扣")]), _c("div", {
    staticClass: "logDetail payinfo_bg"
  }, [_vm._v(_vm._s("--"))])]), _c("div", [_c("p", {
    staticClass: "contitle"
  }, [_vm._v("总计")]), _c("div", {
    staticClass: "logDetail payinfo_bg"
  }, [_vm._v(_vm._s(_vm.info.amount))])])])]), _c("a-card", {
    staticClass: "saleInfo"
  }, [_c("h3", [_vm._v("售后信息")]), _c("div", {
    staticClass: "detail"
  }, [_c("div", [_c("span", {
    staticClass: "contKey"
  }, [_vm._v("售后订单编号")]), _c("span", {
    staticClass: "contVal"
  }, [_vm._v(_vm._s(_vm.afterSaleInfo.aftersaleno || "--"))]), _vm.afterSaleInfo.aftersaleno ? _c("img", {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.afterSaleInfo.aftersaleno,
      expression: "afterSaleInfo.aftersaleno",
      arg: "copy"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.orderCopy,
      expression: "orderCopy",
      arg: "success"
    }],
    staticClass: "copy_icon",
    attrs: {
      src: _vm.copyIcon,
      alt: ""
    }
  }) : _vm._e()]), _c("span", [_vm._v(_vm._s(_vm.afterSaleInfo.closingreason || ""))])]), _c("div", {
    staticClass: "content",
    staticStyle: {
      width: "730px"
    }
  }, [_c("div", [_c("p", {
    staticClass: "contitle"
  }, [_vm._v("退款信息")]), _c("div", {
    staticClass: "logDetail sale_bg"
  }, [_c("div", {
    staticClass: "pay_info_box"
  }, [_c("div", {
    staticClass: "pay_item"
  }, [_c("span", {
    staticClass: "pay_info_item"
  }, [_vm._v("退款时间")]), _c("span", {
    staticClass: "second_item"
  }, [_vm._v(_vm._s(_vm.afterSaleInfo.refundtime || "--"))])]), _c("div", {
    staticClass: "pay_item"
  }, [_c("span", {
    staticClass: "pay_info_item"
  }, [_vm._v("退款渠道")]), _c("span", {
    staticClass: "second_item"
  }, [_vm._v(_vm._s(_vm.paytype(_vm.afterSaleInfo.refundtype)))])]), _c("div", {
    staticClass: "pay_item"
  }, [_c("span", [_vm._v("退款金额")]), _c("span", {
    staticClass: "second_item"
  }, [_vm._v(_vm._s(_vm.afterSaleInfo.refundamount || "--"))])])])])]), _c("div", [_c("p", {
    staticClass: "contitle"
  }, [_vm._v("赔付信息")]), _c("div", {
    staticClass: "logDetail sale_bg"
  }, [_c("div", {
    staticClass: "pay_info_box"
  }, [_c("div", {
    staticClass: "pay_item"
  }, [_c("span", {
    staticClass: "pay_info_item"
  }, [_vm._v("赔付时间")]), _c("span", {
    staticClass: "second_item"
  }, [_vm._v("--")])]), _c("div", {
    staticClass: "pay_item"
  }, [_c("span", {
    staticClass: "pay_info_item"
  }, [_vm._v("退款渠道")]), _c("span", {
    staticClass: "second_item"
  }, [_vm._v("--")])]), _c("div", {
    staticClass: "pay_item"
  }, [_c("span", {
    staticClass: "pay_info_item"
  }, [_vm._v("退款金额")]), _c("span", {
    staticClass: "second_item"
  }, [_vm._v("--")])])])])])])]), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.previewHandleCancel
    }
  }), _c("a-card", {
    staticClass: "action"
  }, [_c("h3", [_vm._v("订单操作日志")]), _c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return it.id;
      },
      loading: _vm.loading,
      bordered: ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (_ref) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record, index) {
          return [_c("span", {
            staticStyle: {
              "font-size": "14px",
              color: "#646464"
            }
          }, [key == "memo" ? _c("span", [_c("span", {
            staticStyle: {
              "white-space": "pre-line"
            }
          }, [_vm._v(_vm._s(record.memo))]), record.imgurls ? _c("span", {
            staticStyle: {
              display: "flex",
              "flex-wrap": "wrap"
            }
          }, _vm._l(record.imgurls.split("|"), function (it) {
            return _c("img", {
              staticClass: "product_img",
              attrs: {
                src: it
              },
              on: {
                click: function click($event) {
                  return _vm.preview(it);
                }
              }
            });
          }), 0) : _vm._e()]) : key == "sellertype" ? _c("span", [_c("span", [_vm._v(_vm._s(_vm.sellerType(record.sellertype)))])]) : _c("span", [_c("span", [_vm._v(_vm._s(record[key] || "--"))])])])];
        }
      };
    })], null, true)
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };