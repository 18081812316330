import Mixin from "@/utils/mixin";
import { saleOrderApi, AfterSaleOrderApi } from "@/api";
export default {
  mixins: [Mixin],
  data: function data() {
    return {
      previewVisible: false,
      previewImage: '',
      copyIcon: "https://static.solestagesupply.com/solestage/5b71cffb4f764024aac941ffd17f551b.png",
      info: {},
      orderForm: "KNOWHERE App",
      logInfo: {},
      //订单地址
      wareInfo: {},
      //仓库地址
      receiveInfo: {},
      // 收货详情
      sendInfo: {},
      //发货详情
      afterSaleInfo: {},
      //售后信息
      sendaddress: "",
      //发货地址
      shipaddress: "",
      //收货地址
      wareaddress: "",
      //仓库地址
      dataSource: [],
      loading: false,
      // createtime: "2021-11-10 07:30:44"
      // id: 242
      // memo: "收件人:jxw  15236282066\n收货地址:Jsjjs,Djjdke,Alabama,Autauga 2501"
      // name: "1483180308@qq.com"
      // operation: "下单"
      // orderid: 235
      // rolename: "买家"
      columns: [{
        title: '时间',
        dataIndex: 'createtime',
        key: 'createtime',
        scopedSlots: {
          customRender: 'createtime'
        },
        width: '10%'
      }, {
        title: '角色',
        dataIndex: 'rolename',
        key: 'rolename',
        scopedSlots: {
          customRender: 'rolename'
        },
        width: '8%'
      }, {
        title: '用户',
        dataIndex: 'name',
        key: 'name',
        scopedSlots: {
          customRender: 'name'
        },
        width: '10%'
      }, {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        scopedSlots: {
          customRender: 'operation'
        },
        width: '8%'
      }, {
        title: '详情',
        dataIndex: 'memo',
        key: 'memo',
        scopedSlots: {
          customRender: 'memo'
        },
        width: '64%'
      }]
    };
  },
  computed: {
    orderType: function orderType() {
      return this.$route.query.orderType;
    },
    // 售后订单信息
    afterStatus: function afterStatus() {
      return function (val) {
        switch (val) {
          case 0:
            return "待付款";
          case 1:
            return "待卖家发货";
          case 2:
            return "待平台收货";
          case 3:
            return "待鉴定";
          case 4:
            return "待平台发货";
          case 5:
            return "待收货";
          case 6:
            return "已完成";
          case 7:
            return "已关闭";
          default:
            return "--";
        }
      };
    },
    paytype: function paytype() {
      return function (val) {
        switch (val) {
          case 1:
            return "Airwallex";
          case 2:
            return "PayPal";
          case 3:
            return "Apple Pay";
          default:
            return "--";
        }
      };
    }
  },
  created: function created() {
    // 销售订单：saleOrder，售后订单：afterSaleOrderInfo
    this.info = JSON.parse(sessionStorage.getItem("".concat(this.orderType, "Info")));
    console.log('订单详情信息', this.info);
    this.getdropshiping(); // 收发货信息
    this.getorderloglist(); // 日志
    this.getaftersaleinfo(); // 售后详情
  },

  methods: {
    orderCopy: function orderCopy(e) {
      this.toast("复制成功", "success");
    },
    previewHandleCancel: function previewHandleCancel() {
      this.previewVisible = false;
    },
    preview: function preview() {
      var previewImage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
      this.previewImage = previewImage;
      this.previewVisible = true;
    },
    getdropshiping: function getdropshiping() {
      var _this = this;
      saleOrderApi.getAdresInfo({
        orderno: this.info.orderno
      }).then(function (res) {
        console.log('收发货信息', res);
        _this.receiveInfo = res.receive;
        _this.wareInfo = res.wareHouse;
        _this.sendInfo = res.send;
        //收货地址
        // this.shipaddress =
        //     (this.logInfo.country ? this.logInfo.country : "") +
        //         (this.logInfo.state
        //             ? "-" + this.logInfo.state + "-"
        //             : "") +
        //         (this.logInfo.city ? this.logInfo.city + "-" : "") +
        //         (this.logInfo.streetaddress
        //             ? this.logInfo.streetaddress
        //             : "") || "--";
        //发货地址
        // this.sendaddress =
        //     (this.logInfo.returncountry
        //         ? this.logInfo.returncountry
        //         : "") +
        //         (this.logInfo.returnstate
        //             ? "-" + this.logInfo.returnstate + "-"
        //             : "") +
        //         (this.logInfo.returncity
        //             ? "-" + this.logInfo.returncity + "-"
        //             : "") +
        //         (this.logInfo.returnstreetaddress
        //             ? this.logInfo.returnstreetaddress
        //             : "") || "--";
        //仓库地址
        // this.wareaddress =
        //     (this.wareInfo.country ? this.wareInfo.country : "") +
        //         (this.wareInfo.state
        //             ? "-" + this.wareInfo.state + "-"
        //             : "") +
        //         (this.wareInfo.city ? this.wareInfo.city + "-" : "") +
        //         (this.wareInfo.streetaddress
        //             ? this.wareInfo.streetaddress
        //             : "") || "--";
      });
    },
    getorderloglist: function getorderloglist() {
      var _this2 = this;
      this.loading = true;
      saleOrderApi.getorderloglist({
        id: this.info.id
      }).then(function (res) {
        console.log('日志', res);
        _this2.dataSource = res;
        _this2.loading = false;
      }).catch(function () {
        setTimeout(function () {
          _this2.loading = false;
        }, 3000);
      });
    },
    getaftersaleinfo: function getaftersaleinfo() {
      var _this3 = this;
      AfterSaleOrderApi.getaftersaleinfo({
        orderno: this.info.orderno
      }).then(function (res) {
        _this3.afterSaleInfo = res;
        console.log('售后详情', res);
      });
    }
  }
};